
import GenelIstatistikPerformansGrafik from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik.vue";
import MuvekkilTableInfo from "@/components/infos/raporlama-ofispro/MuvekkilTableInfo.vue";
import UstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/UstBasliklar.vue";
import TahsilatlarChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarChart.vue";
import TahsilatlarDataTable from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarDataTable.vue";
import Dates from "@/components/inputs/Dates.vue";
import {Component, Vue} from "vue-property-decorator";
import GenelIstatistikGrafikInfo from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo.vue";
import OzelMenuRaporlama from "@/components/infos/raporlama-ofispro/helper-comps/OzelMenuRaporlama.vue";
import KarsilastirmaliGrafikChart from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikChart.vue";
import KarsilastirmaDataTable from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaDataTable.vue";
import KarsilastirmaliGrafikColChart
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikColChart.vue";
import TakipTahsilatWidget from "@/components/infos/raporlama-ofispro/TakipTahsilatWidget.vue";
import TakipVeTahsilatKarsilastirmaliGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatKarsilastirmaliGrafik.vue";
import TakipVeTahsilatWidgetGrafik from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatWidgetGrafik.vue";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import TakipVeTahsilatDataTable from "@/components/infos/raporlama-ofispro/TakipVeTahsilatDataTable.vue";
import TakipVeTahsilatAyGrafik from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatAyGrafik.vue";
import TakipVeTahsilatHaftalikGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatHaftalikGrafik.vue";
@Component({
  components: {
    TakipVeTahsilatHaftalikGrafik,
    TakipVeTahsilatAyGrafik,
    TakipVeTahsilatDataTable,
    OzelAralikDatePicker,
    TakipVeTahsilatWidgetGrafik,
    TakipVeTahsilatKarsilastirmaliGrafik,
    TakipTahsilatWidget,
    KarsilastirmaDataTable,
    KarsilastirmaliGrafikChart,
    OzelMenuRaporlama,
    UstBasliklar, MuvekkilTableInfo, GenelIstatistikPerformansGrafik, TahsilatlarChart, TahsilatlarDataTable, Dates,GenelIstatistikGrafikInfo}
})
export default class KarsilastirmaliRaporlamaView extends Vue {

  selectedItem= null
  yillikKontrol:boolean = true
  aylikKontrol:boolean = false
  haftalikKontrol:boolean = false
  items= ["Geçen Yıl", "Bu Yıl","Geçen Ay","Bu Ay","Geçen Hafta","Bu Hafta"]



  selectItem(item:any) {
    this.selectedItem = item;
    if (item === "Bu Yıl" || item === "Geçen Yıl") {
      this.yillikKontrol = true;
      this.aylikKontrol = false;
      this.haftalikKontrol = false;
    }else if(item === "Bu Ay" || item === "Geçen Ay"){
      this.yillikKontrol = false;
      this.aylikKontrol = true;
      this.haftalikKontrol = false;
    }else if (item === "Bu Hafta" || item === "Geçen Hafta"){
      this.yillikKontrol = false;
      this.aylikKontrol = false;
      this.haftalikKontrol = true;
    }
  }

};
